type Single =
  | CookieTypeEnum.Analytics
  | CookieTypeEnum.Behavioral
  | CookieTypeEnum.ErrorTracking
  | CookieTypeEnum.Functional;
type Double = `${Single}${Single}`;
type Triple = `${Single}${Single}${Single}`;
type Quad = `${Single}${Single}${Single}${Single}`;

export enum CookieTypeEnum {
  Functional = 'f',
  Analytics = 'a',
  Behavioral = 'b',
  ErrorTracking = 'e',
}

export type AcceptedCookiePolicyType = Single | Double | Triple | Quad | '';

export enum AcceptedCookiePolicyEnum {
  None = '',
  All = CookieTypeEnum.Functional +
    CookieTypeEnum.Analytics +
    CookieTypeEnum.Behavioral +
    CookieTypeEnum.ErrorTracking,
}

export type CookieSettingType = {
  title: string;
  description: string;
  type: CookieTypeEnum;
  required?: boolean;
};
