import { CookieStorageKey } from '~/app/[locale]/_components/CookieBanner/constants';
import {
  AcceptedCookiePolicyEnum,
  AcceptedCookiePolicyType,
  CookieSettingType,
  CookieTypeEnum,
} from '~/app/[locale]/_components/CookieBanner/types';

/**
 * Checks if the Error Tracking Cookie is present in the user's browser.
 *
 * It does this by retrieving the document's cookies, finding the one with the name
 * specified in the CookieStorageKey constant, and then checking if it contains the
 * Error Tracking Cookie type specified in the CookieTypeEnum constant.
 *
 * If the cookie is present, the function returns true. Otherwise, it returns false.
 */
export const hasErrorTrackingCookie = () => {
  const cookie = document.cookie
    .split(';')
    .find((cookie) => cookie.includes(CookieStorageKey))
    ?.split('=')[1];
  return cookie?.includes(CookieTypeEnum.ErrorTracking) ?? false;
};

export const getCookieSettings = (): CookieSettingType[] => [
  {
    title: 'modal.functional.title',
    description: 'modal.functional.description',
    type: CookieTypeEnum.Functional,
    required: true,
  },
  {
    title: 'modal.analytics.title',
    description: 'modal.analytics.description',
    type: CookieTypeEnum.Analytics,
  },
  {
    title: 'modal.behavioral.title',
    description: 'modal.behavioral.description',
    type: CookieTypeEnum.Behavioral,
  },
  {
    title: 'modal.errorTracking.title',
    description: 'modal.errorTracking.description',
    type: CookieTypeEnum.ErrorTracking,
  },
];

export const getInitiallySelectedCookies = (
  acceptedCookiePolicy: AcceptedCookiePolicyType
) => {
  if (
    acceptedCookiePolicy === AcceptedCookiePolicyEnum.All ||
    !acceptedCookiePolicy
  ) {
    return getCookieSettings().map((setting) => setting.type);
  }

  return acceptedCookiePolicy.split('') as CookieTypeEnum[];
};
